import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetServerSideProps } from 'next';

import { getCategories, DataCategory } from 'data/Category';
import { RecommendItem } from 'data/Recommend';
import { NewsListItem } from 'data/News';
import { PickItem } from 'data/Pick';
import { LinkItem } from 'data/Link';
import { MapItem } from 'data/Map';
import Layout from 'components/Layout';
import Recommend from 'components/home/Recommend';
import News from 'components/home/News';
import PickedPages from 'components/home/PickedPages';
import UsefulLinks from 'components/home/UsefulLinks';
import LegacyMap from 'components/home/LegacyMap';
import InstagramFeed from 'components/home/InstagramFeed';
import MailSubscriber from 'components/MailSubscriber';
import { getHomePageData } from 'data/HomePage';
import Map from 'components/home/Map';

interface HomePageProps {
  categories: DataCategory[];
  recommends: RecommendItem[];
  news: NewsListItem[];
  picks: PickItem[];
  links: LinkItem[];
  map: MapItem[];
}

export default function HomePage({
  categories,
  recommends,
  news,
  picks,
  links,
  map,
}: HomePageProps) {
  return (
    <Layout
      categories={categories}
      localizations={{ cz: '/', de: '/de', en: '/en' }}
    >
      {recommends.length > 0 && news.length > 0 && (
        <div className="Container Container--waves">
          <Recommend recommends={recommends} />
          <News news={news} />
          <div className="Container__helper" />
        </div>
      )}
      {picks.length > 0 && links.length > 0 && (
        <div className="Container">
          <PickedPages picks={picks} />
          <UsefulLinks links={links} />
        </div>
      )}
      {map.length > 0 && <Map items={map} />}
      <InstagramFeed />
      <MailSubscriber />
    </Layout>
  );
}

export const getServerSideProps: GetServerSideProps<HomePageProps> = async ({
  locale,
  res,
}) => {
  const categories = await getCategories(locale!);
  const { news, recommends, picks, links, map } = await getHomePageData(
    locale!,
  );

  res.setHeader('Cache-Control', 'max-age=0, s-maxage=300');
  return {
    props: {
      categories,
      recommends,
      news,
      picks,
      links,
      map,
      ...(await serverSideTranslations(locale!, ['common'])),
    },
  };
};
