import { useTranslation } from 'next-i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Script from 'next/script';

import { useWindowSize } from 'hooks/useWindowSize';

const SELECTOR_POSTS_SLIDER = '.es-layout';

function InstagramFeed() {
  const flag = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('common');
  const { width } = useWindowSize();
  const [ready, setReady] = useState(false);

  const calculate = useCallback((width?: number) => {
    const slider = document.querySelector<HTMLDivElement>(
      SELECTOR_POSTS_SLIDER,
    );

    if (!flag.current || width === undefined || !slider) return;

    setReady(true);
    if (width <= 1156) {
      slider.style.paddingLeft = `24px`;
    } else if (width <= 600) {
      slider.style.paddingLeft = `16px`;
    } else {
      slider.style.paddingLeft = `${flag.current.offsetLeft}px`;
    }
  }, []);

  useEffect(() => {
    setTimeout(() => calculate(width), 1000);
    setTimeout(() => calculate(width), 2000);
    setTimeout(() => calculate(width), 3000);
  }, [calculate, width]);

  useEffect(() => {
    calculate(width);
    setTimeout(calculate, 10);
  }, [calculate, width]);

  const message = t('PHOTO_MESSAGE', {
    hashtags: `#idecin`,
  });

  return (
    <>
      <Script
        src="//apps.elfsight.com/p/platform.js"
        strategy="lazyOnload"
        onLoad={() => setTimeout(() => calculate(width), 1000)}
      />
      <div className="InstagramFeed">
        <div className="Container">
          <div className="Flag" ref={flag}>
            {t('PHOTO_TITLE')}
          </div>
          <p dangerouslySetInnerHTML={{ __html: message }} />
        </div>
        <div
          className={classNames('InstagramFeed__embed', {
            'InstagramFeed__embed--ready': ready,
          })}
        >
          <div className="elfsight-app-4e10dfcb-7077-44bc-b95b-155b6a4f3de5" />
        </div>
      </div>
    </>
  );
}

export default InstagramFeed;
