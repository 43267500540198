import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';

import { NewsListItem } from 'data/News';
import { Routes } from 'utils/routes';

interface NewsProps {
  news: NewsListItem[];
}

function News({ news }: NewsProps) {
  const { t } = useTranslation('common');
  return (
    <div className="NewsList">
      <div className="NewsList__title">{t('NEWS_TITLE')}</div>
      <div className="NewsList__shadow">
        <div className="NewsList__scroll">
          {news.map(({ title, subtitle, date_published, path }, key) => (
            <Link key={path} href={path} passHref>
              <a
                className={classNames('NewsList__item', {
                  'NewsList__item--last': key + 1 === news.length,
                })}
              >
                <small>{date_published}</small>
                <h4>{title}</h4>
                <p>{subtitle}</p>
              </a>
            </Link>
          ))}
          <Link href={Routes.news} passHref>
            <a className="NewsList__linkMore">{t('NEWS_MORE')}</a>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default News;
