import { useTranslation } from 'next-i18next';
import { FormEventHandler, useCallback, useState } from 'react';
import { useRouter } from 'next/router';

import { isEmail } from 'utils/string';
import { MailSubscribeResult } from 'pages/api/mail-subscribe';

function MailSubscriber() {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [completed, setCompleted] = useState(false);

  const subscribe = useCallback<FormEventHandler<HTMLFormElement>>(
    async event => {
      event.preventDefault();
      event.stopPropagation();

      if (!isEmail(email) || !locale) return;

      setLoading(true);
      setCompleted(false);
      try {
        const response = await fetch('/api/mail-subscribe', {
          method: 'POST',
          body: JSON.stringify({ email, language: locale }),
        });

        const { success } = (await response.json()) as MailSubscribeResult;
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
        setCompleted(true);
      }
    },
    [email, locale],
  );

  return (
    <div className="Container">
      <div className="MailSubscriber">
        <div className="Flag">{t('MAIL_SUBSCRIBER_TITLE')}</div>
        <div className="MailSubscriber__box">
          <p className="MailSubscriber__info">{t('MAIL_SUBSCRIBER_MESSAGE')}</p>
          {completed ? (
            <span className="MailSubscriber__success">
              {t('MAIL_SUBSCRIBER_THANKS')}
            </span>
          ) : (
            <form onSubmit={subscribe} className="MailSubscriber__form">
              <input
                type="email"
                placeholder={t('MAIL_SUBSCRIBER_EMAIL')}
                className="MailSubscriber__input"
                value={email}
                onChange={e => setEmail(e.target.value)}
                disabled={loading}
              />
              <button
                type="submit"
                disabled={!isEmail(email) || loading}
                className="MailSubscriber__button"
              >
                {loading
                  ? t('MAIL_SUBSCRIBER_SENDING')
                  : t('MAIL_SUBSCRIBER_SEND')}
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default MailSubscriber;
