import MapBox, {
  MapRef,
  Marker,
  NavigationControl,
  Popup,
  ViewState,
} from 'react-map-gl';
import { useTranslation } from 'next-i18next';
import classNames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import Image from 'next/image';

import { MapItem, MapItemPoi } from 'data/Map';
import useDarkMode from 'hooks/useDarkMode';

type Props = {
  items: MapItem[];
};

function Map({ items }: Props) {
  const ref = useRef<MapRef>(null);
  const { t } = useTranslation('common');
  const isDarkMode = useDarkMode();
  const [viewState, setViewState] = useState<ViewState>({
    longitude: 50,
    latitude: 14,
    zoom: 16,
    pitch: 10,
    padding: { top: 0, left: 0, right: 0, bottom: 0 },
    bearing: 0,
  });

  const [item, setItem] = useState<MapItem | null>(null);
  const [poi, setPoi] = useState<MapItemPoi | null>(null);

  const fitMapToBounds = useCallback((pois: MapItemPoi[]) => {
    if (pois.length === 0 || !ref.current) return;

    const lngs = pois.map(p => p.position.longitude);
    const lats = pois.map(p => p.position.latitude);
    const minLng = Math.min(...lngs);
    const maxLng = Math.max(...lngs);
    const minLat = Math.min(...lats);
    const maxLat = Math.max(...lats);

    ref.current.fitBounds(
      [
        [minLng, minLat],
        [maxLng, maxLat],
      ],
      {
        padding: 80,
        duration: 1000,
      },
    );
  }, []);

  useEffect(() => {
    if (item || items.length === 0) return;
    setItem(items[0]);
  }, [item, items]);

  useEffect(() => {
    const resolveResize = () => fitMapToBounds(item?.pois ?? []);
    window.addEventListener('resize', resolveResize);
    return () => window.removeEventListener('resize', resolveResize);
  }, [fitMapToBounds, item]);

  if (!process.env.NEXT_PUBLIC_MAPBOX_KEY) return null;
  return (
    <div className="Map">
      <div className="Container">
        <div className="Flag">{t('MAP_TITLE')}</div>
        <div className="Map__content">
          <MapBox
            ref={ref}
            {...viewState}
            onMove={evt => setViewState(evt.viewState)}
            onLoad={() => {
              if (!item) return;
              fitMapToBounds(item.pois);
            }}
            scrollZoom={false}
            antialias
            logoPosition="bottom-right"
            mapboxAccessToken={process.env.NEXT_PUBLIC_MAPBOX_KEY}
            dragPan
            dragRotate
            style={{ width: '100%', height: '100%' }}
            mapStyle={
              isDarkMode
                ? 'mapbox://styles/forcandies/cluyj9d0h004301oc592g1si6'
                : 'mapbox://styles/forcandies/cluyrtaor004f01qrb989684e'
            }
          >
            <NavigationControl position="bottom-right" />
            {item?.pois.map((poi, key) => (
              <Marker
                key={key}
                longitude={poi.position.longitude}
                latitude={poi.position.latitude}
                style={{ cursor: 'pointer' }}
              >
                <Image
                  src={`/pins/pin-${poi.type}.svg`}
                  alt={poi.title}
                  width={36}
                  height={45}
                  onClick={() => setPoi(poi)}
                />
              </Marker>
            ))}
            {poi && (
              <Popup
                key={`${poi.position.longitude}-${poi.position.latitude}`}
                longitude={poi.position.longitude}
                latitude={poi.position.latitude}
                anchor="bottom"
                closeButton={false}
                offset={24}
                maxWidth="300px"
                className="Map__box"
              >
                {poi.link && (
                  <a
                    href={poi.link}
                    target="_blank"
                    className={classNames('Map__boxTitle', {
                      'Map__boxTitle--external': poi.external && poi.link,
                      'Map__boxTitle--noLink': !poi.link,
                    })}
                    rel="noreferrer"
                  >
                    {poi.title}
                  </a>
                )}
                {!poi.link && (
                  <strong
                    className={classNames('Map__boxTitle', {
                      'Map__boxTitle--external': poi.external && poi.link,
                      'Map__boxTitle--noLink': !poi.link,
                    })}
                  >
                    {poi.title}
                  </strong>
                )}
                <div className="Map__boxDescription">{poi.subtitle}</div>
              </Popup>
            )}
          </MapBox>
        </div>
        <div className="Map__types">
          {items.map(i => (
            <div
              key={i.uid}
              onClick={() => {
                setItem(i);
                setPoi(null);
                fitMapToBounds(i.pois);
              }}
              className={classNames('Map__type', {
                'Map__type--active': i.uid === item?.uid,
              })}
            >
              {i.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Map;
